@import '@doseme/cohesive-ui/dist/style/colors.scss';

.check-sso-page-panel {
  border-radius: 24px;
  background-color: $white;
  box-shadow: 0px 8px 24px rgba(18, 50, 120, 0.04);
  margin-top: 64px;
  padding: 40px 48px;
  margin-left: auto;
  margin-right: auto;
  color: $charcoal-grey;

  height: 314px;

  .check-sso-loading-spinner {
    justify-content: center;
    display: flex;
    margin-top: 80px;
  }

  .co-validation-message {
    display: none;
  }

  .co-btn-md {
    border-radius: 40px !important;
  }

  &.larger-width {
    width: 640px;
  }

  &.smaller-width {
    width: 440px;
  }

  .check-sso-title {
    color: $doseme-blue;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .check-sso-subtitle {
    font-size: 16px;
    color: $gunmetal;
    font-weight: 700;
  }
}

.invalid-sso-page-panel {
  .invalid-sso-title {
    color: $doseme-warning;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  .invalid-sso-body {
    margin-top: 8px;
    font-size: 14px;
  }

  .invalid-sso-info-bubble {
    margin-top: 24px;
  }
}

.w-440 {
  width: 440px;
}
