@import '@doseme/cohesive-ui/dist/style/colors.scss';

.simulation-panel {
  position: relative;
}

.predicted-outcomes-calculate-overlay {
  margin-top: 39px;
}

.sim-panel-subheading-indpop {
  align-items: center;
  height: 36px;
}

.sim-panel-subheading {
  display: flex;
  align-items: center;

  &-higherlower {
    margin-left: 6px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  &-predicted {
    margin-left: 6px;
    font-weight: bold;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
  }
}

.custom-predicted-header {
  width: 236px;
  display: flex;
}

.co-stats-panel {
  height: 100px;
}

.custom-target-text-wrapper {
  margin-right: 8px;
  flex-direction: column;
  width: 60%;
}

.custom-subheading {
  height: 39px;
  margin-bottom: 14px;
}

.custom-form-wrapper {
  .co-stats-header {
    padding: 0 0 20px 0;
  }

  .co-stats-value {
    padding: 0;
  }

  .co-textdropdown-chevron {
    transform: inherit;
  }

  .co-stats-panel {
    align-items: baseline;
  }

  .customdose-dose-form-field, .custom-form-input{
    width: 80px !important;
  }
}

.outcomes-dose-stat {
  width: 25%;
  padding-right: 8px;
}

.outcomes-other-stat {
  width: 75%;
}

.outcomes-dose-stat-timetotrough {
  .co-stats-panel {
    width: 96px;

    .co-stats-title {
      white-space: normal;
    }

    .co-statistics-value-header-style{
      min-height: 57px;
      align-items: center;
    }

    .co-statistics-value-style {
      min-height: auto;
    }
  }
}

.custom-form {
  &-input {
    width: 64px;
  }

  &-input-1 {
    width: 64px;
    margin-left: 4px;
  }

  input {
    text-align: center;
  }

  .co-form-field-label {
    display: none;
  }
}

.custom-form-input {
  border-radius: 8px;
}

.custom-form-input,
.customdose-dose-form-field {
  width: 64px;
  input[type='text'] {
    text-align: center !important;
  }
  margin-top: -9px;
}

.custom-form-input-timetotrough, .custom-form-input-cumulative_auc {
  width: 98px;
}

.full-width-overlay {
  width: 100%;

  .flex-column {
    width: 100%;
  }

  .co-overlay-container {
    width: 100%;

    .co-overlay-content {
      display: block;
    }
  }
}

.predicted-outcomes-header {
  width: 237px;

  .info-icon {
    margin-top: 3px;
  }
}

.predicted-outcomes-content {
  display: flex;
}

.report-bar {
  height: 48px;
  font-size: 0.9375rem;
  border-radius: 16px;
  padding: 0 12px;
  margin-top: 16px;

  &.report-bar-disabled {
    background-color: $toggle-unselected;
    color: $loading-grey;

    .co-list-button {
      border: none;
      color: $toggle-grey;
    }
  }

  &.report-bar-enabled {
    background-color: $pale-orange;
  }

  .co-list-button {
    color: $black;
  }
}

.edit-bar {
  height: 48px;
  font-size: 0.9375rem;
  border-radius: 16px;
  padding: 0 12px;
  margin-top: 16px;

  &.edit-bar-disabled {
    background-color: $toggle-unselected;
    color: $loading-grey;

    .co-list-button {
      border: none;
      color: $toggle-grey;
    }
  }

  &.edit-bar-enabled {
    background-color: $input-grey;
  }

  .co-list-button {
    color: $black;
  }
}

.initial-calc-btn-disabled {
  background-color: $lightgrey !important;

  &:hover {
    cursor: default;
  }
}

.custom-stats-wrapper {
  height: 100px;
  width: 100%;
}

/* overrides */
.co-overlay-container {
  width: 100%;
  .co-overlay-covered-content {
    width: 100%;
  }
}

.custom-field-invalid-wrapper {
  position: relative;
  margin-top: -8px;

  .custom-field-invalid-indicator {
    position: absolute;
    right: 1px;
    top: -10px;
    z-index: 4;
  }
}

.validation-error-number {
  color: $white;
  background-color: $highlight-red;
  width: 16px;
  height: 16px;
  font-size: 0.8125rem;
  font-weight: bold;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sim-panel-calculated-subtitle {
  margin-top: 5px;
  margin-bottom: 14px;
  display: inline-block;
  position: relative;
  font-size: 13px;
}

.generate-report-list-button {
  white-space: nowrap;
}

.datetimeinput-line-buffer {
  display: flex;
  padding-top: 5px;
}

.sim-time-picker > .w-100,
.sim-date-picker > .w-100 {
  display: none;
}

.sim-time-picker {
  margin-left: 0.5rem;
}

.sim-date-picker > .react-datepicker-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.course-regimen-text {
  width: 75%;
  font-size: 14px;
}

.co-control-btn-icon-center {
  font-size: 16px;
}

.sim-panel-interval-title-and-info {
  display: flex;
  flex-direction: row;

  .sim-panel-interval-info {
    height: 16px;

    .sim-panel-interval-info-icon {
      margin-left: 5px;
    }
  }
}

.individualized-info-modal-button {
  margin-top: 4px;
}

.dose-recommendation-outer {
  flex-direction: column;
  display: flex;
  width: 60%;
  margin-right: 8px;
}

.predicted-outcomes-outer {
  flex-direction: column;
  width: 40%;

  .co-overlay-content {
    display: block;
  }
}

.custom-outcomes-dose-stat-time-to-trough,
.custom-predicted-outcomes-outer-time-to-trough,
.indpop-predicted-outcomes-outer-time-to-trough {
  .co-statistics-value-header-style {
    height: 49px;
    align-items: center;

    .co-stats-title {
      margin-bottom: 0px;
      white-space: normal;
    }
  }

  .co-statistics-value-style {
    height: 40px;
    padding: 0px;
    text-align: center;
  }
}

.custom-outcomes-dose-stat-time-to-trough {
  .co-stats-panel {
    width: 100px;
  }
}

.indpop-predicted-outcomes-outer-time-to-trough {
  .co-stats-panel {
    width: 260px;
  }
}

#edit-simulate-hd-bar {
  margin-bottom: 18px;
}

.dosing-matrix-info-bubble {
  color: $neutrals-700;
  font-size: 14px;
  font-weight: 700;
  display: flex;

  .dosing-matrix-hyperlink {
    cursor: pointer;
    color: $blue-steel-600;
    margin-left: 8px;
    font-weight: 400;
    text-decoration: underline;
  }

  .dosing-matrix-hyperlink-no-underline {
    color: $blue-steel-600;
    margin-left: 4px;
  }
}
