@import '@doseme/cohesive-ui/dist/style/colors.scss';

.dosing-matrix--modal-subheading-type {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 8px;
}

.dosing-matrix--modal-subheading-population {
  color: $plot-population;
}

.dosing-matrix--modal-subheading-individualized {
  color: $plot-individualised;
}

.dosing-matrix-modal-subheading {
  font-size: 16px;
  font-weight: 400;
  display: flex;
}

.dosing-matrix-btn {
  transform: translate(15px, 4px);

  svg {
    filter: drop-shadow(0px 3px 3px rgba(18, 50, 120, 0.16));
  }
}
