@import '@doseme/cohesive-ui/dist/style/colors.scss';

.doseme-analytics-content {
  width: 100%;
  max-width: 1040px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 86px;

  .analytics-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin-top: 56px;
    width: 100%;

    .analytics-title {
      font-size: 24px;
      font-weight: normal;
      padding-right: 24px;

      .analytics-hospital-name {
        font-weight: 700;
      }
    }
  }

  .doseme-analytics-panel {
    border-radius: 24px;
    background-color: $white;
    padding: 24px;
    box-shadow: 0 0 16px rgba(18, 50, 120, 0.18);
    position: relative;

    .data-filters-title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 17px;
    }

    .data-filters-date-range {
      width: 332px;
      height: 90px;
      gap: 8px;
      border-radius: 12px;
      padding: 12px;
      border-width: 2px;
      background-color: $neutrals-50;
      border: 2px solid $blue-steel-200;
      margin-right: 8px;
      display: flex;
      z-index: 100;

      .end-date-range {
        transform: translateY(16px);
      }
    }

    .data-filters-context-filters {
      width: 747px;
      min-height: 90px;
      gap: 16px;
      border-radius: 12px;
      padding: 12px;
      background: $blue-steel-200;
      align-content: end;
    }

    .analytics-accordion-outer {
      background-color: $neutrals-100;
      border-radius: 16px;
      padding: 12px;
      margin-top: 12px;
    }

    .co-accordion-parent {
      margin-bottom: 12px!important;
    }
  }

  .doseme-analytics-chart-panel {
    margin-top: 16px;
    padding-top: 16px!important;
  }

  .doseme-analytics-chart-panel.row {
    display: flex;
  }

  .doseme-analytics-chart {
    margin-bottom: 16px;

    .header-row {
      height: 24px;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
    }

    .title {
      color: $blue-steel-500;
      font-family: "Arial", "sans-serif";
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
    }

    .view-options {
      border: 2px solid $neutrals-200;
      border-radius: 16px;
      color: $neutrals-200;
    }

    .plot-disclaimer {
      margin-top: 35px;
      padding: 0 25px;
      font-size: 16px;
      color: $darkgrey;
    }

    .co-information-bubble {
      display: flex;

      .doseme-analytics-chart-bubble {
        color: $black;
        margin-left: 8px;
      }
    }
  }
}

.doseme-analytics-chart-plot .recharts-cartesian-axis-tick-value {
  font-family: "Arial", "sans-serif";
  font-size: 10px;
}

.doseme-analytics-chart-plot .recharts-brush-texts {
  color: $charcoal-grey !important;
  //hide the traveller text labels by default
  font-size: 0 !important;
}

.doseme-analytics-chart-plot .custom-tooltip-outer {
  border: 2px solid $blue-steel-300;
  border-radius: 4px;
  width: auto;
  height: auto;
  background-color: $snow;
  padding: 6px;

  .doseme-analytics-tooltip {
    font-family: "Arial", "sans-serif";
    font-size: 12px;
    color: $black;

    .tooltip-title {
      font-weight: 500;
      padding-bottom: 4px;
    }

    .tooltip-content {
      font-weight: 400;
    }

    .tooltip-prefix {
      font-weight: inherit;
    }

    .tooltip-suffix {
      font-weight: inherit;
    }

    .tooltip-record-count {
      font-weight: bold;
    }
  }
}

.doseme-analytics-legend-panel {
  border: 1px solid $neutrals-200;
  background-color: $neutrals-100;
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 14px;

  &.legend-right {
    margin-left: 44px;
  }

  &.aki-risk {
    margin-top: -8px;
    margin-left: 44px;

    .doseme-analytics-legend-item {
      display: block;

      .aki-risk-info-icon {
        margin-left: auto;
      }

      &:first-child {
        margin-top: 0px;
      }
    }
  }

  .doseme-analytics-legend-item {
    border: 1px solid $neutrals-200;
    background-color: $neutrals-50;
    padding: 6px;
    border-radius: 6px;
    display: flex;

    &.legend-right {
      margin-top: 8px;
    }

    .doseme-analytics-legend-item-icon {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      margin-right: 8px;
    }
  }
}
